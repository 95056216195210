import axios from 'axios';

export async function sendMsgToBedrockAI(text) {    
  const url = `https://9vp8hvof23.execute-api.us-east-1.amazonaws.com/POC/gptService`;
  const data = {
   "model": "Bedrock-JURRASIC",
   "messages": text 
 };
   // Make a request to BEDROCK API
   try {
     const response = await axios.post(
       url,
      data,
       {
         headers: {
           'Accept': 'application/json',
           'x-api-key': `o8N16l73DC91aqk7UkR9PaG57V1Ftfpi8fMp9XQr`
         },
       }
     );  
    return response.data.body;
}
catch (error) {
    console.error('An error occurred:', error);
  }
}
