import axios from 'axios';

export async function sendMsgToChatGPT(text) {       
  const url = 'https://api.openai.com/v1/chat/completions';
  const data = {
    model: "gpt-3.5-turbo-0125",
    
    messages: [{ "role": "user", "content": text }]
  };
    // Make a request to the ChatGPT API
    try {
      const response = await axios.post(
        url,
       data,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer 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`,
          },
        }
      ); 
     return response.data.choices[0].message.content;   
}
catch (error) {
    console.error('An error occurred:', error);
  }
}

