import './App.css';
//import gptLogo from './assets/chatgpt.svg';
import gptLogo from './assets/AICHAT.png';
import addBtn from './assets/add-30.png';
import msgIcon from './assets/message.svg';
import home from './assets/home.svg';
import saved from './assets/bookmark.svg';
import rocket from './assets/rocket.svg'
import sendBtn from './assets/send.svg';
import userIcon from './assets/user-icon.png';
import BedrockAI from './assets/bedrock.png';
import iconAI from './assets/AI.png';
import OpenAI from './assets/chatgptLogo.svg';
//import Select from 'react-select';
//import { sendMsgToOpenAI } from './openai';
import { sendMsgToChatGPT } from './chatgpt';
import { sendMsgToBedrockAI } from './BedrockAI';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
//import axios from 'axios';
//import TypingAnimation from "./TypingAnimation"; 


function App() {
const msgEnd = useRef(null);
const [input,setInput] = useState ("")
const [isLoading, setIsLoading] = useState(false);
const[messages,setMessages] = useState([]);

  useEffect(()=>{
    msgEnd.current.scrollIntoView();
  },[messages]);
  
const [selectedOption, setSelectedOption] = useState("BedrockAI")  
    function onValueChange(event){
        setSelectedOption(event.target.value) 
        console.clear()  
       // setMessages([]);     
    }

const handleSend = async() => {
  const  text = input;
  setInput('');
  setMessages([
    ...messages,
    {text,isBot:false}
  ])
  setIsLoading(true);
  //------------------

   if(selectedOption  === 'OpenAI') {
   const res = await sendMsgToChatGPT(text)
      setMessages([
     ...messages,
   {text, isBot: false},
   {text: res, isBot: true}
 ]);
 console.log(res);
  }
  if(selectedOption === 'BedrockAI') {
     const res = await sendMsgToBedrockAI(text)
        setMessages([
       ...messages,
     {text, isBot: false},
     {text: res, isBot: true}
   ]);
   console.log(res);
 } 
 //------------------
setIsLoading(false);
}
const handleEnter = async(e) => {
 if (e.key === 'Enter') await  handleSend(); 
}
const handleQuery =async(e) => {
  const  text = e.target.value;
  setMessages([
    ...messages,
    {text,isBot:false}
  ])
  setIsLoading(true);
  if(selectedOption  === 'OpenAI') {
  const res = await sendMsgToChatGPT(text)
     setMessages([
    ...messages,
  {text, isBot: false},
  {text: res, isBot: true}
]);
console.log(res);
 }
 if(selectedOption === 'BedrockAI') {
    const res = await sendMsgToBedrockAI(text)
       setMessages([
      ...messages,
    {text, isBot: false},
    {text: res, isBot: true}
  ]);
  console.log(res);
} 
setIsLoading(false);
}
return (
<div className="App">  
<div className="sideBar">   
    <div className="upperSide"> 
                <div className="upperSideTop" > <img src ={gptLogo} alt ="Logo" className='logoimg' /> <span className="brand">\\\\</span></div>   
                <br></br>        
                <button className="midBtn" onClick={ () => {window.location.reload()}} ><img src={addBtn} alt="New Chat" className="addBtn"/>New Chat </button>  
                <br></br>    <br></br>  
                <div className="upperSideBottom"> 
                <button className="query" onClick={handleQuery} value={"Databricks Unity Catalog ?"}><img src={msgIcon} alt="Query" className="addBtn"/>Databricks Unity Catalog ? </button>
                <br></br>  
                <button className="query" onClick={handleQuery} value={"Tableau Super Tables ?"} ><img src={msgIcon} alt="Query" className="addBtn"/>Tableau Super Tables ?</button>
                <br></br>  
                <button className="query" onClick={handleQuery} value={"Agile roles in Spanish ?"} ><img src={msgIcon} alt="Query" className="addBtn"/>Agile roles in Spanish ?</button>
                <br></br>  
                <button className="query" onClick={handleQuery} value={"Publications Citation Styles ?"} ><img src={msgIcon} alt="Query" className="addBtn"/>Publications Citation Styles ?</button>
                <br></br>  
                <button className="query" onClick={handleQuery} value={"Rare Diseases drug names ?"} ><img src={msgIcon} alt="Query" className="addBtn"/>Rare Diseases drug names ?</button>
                </div>
            </div>
            <div className="lowerSide">   
                <div className="listItems"><img src={home} alt="" className="listItemsImg"/> Home</div>
                <div className="listItems"><img src={saved} alt="" className="listItemsImg"/> Saved</div>
                <div className="listItems"><img src={rocket} alt="" className="listItemsImg"/> Upgrade</div>
            </div>
      </div>

<div className="main1"> 
<br></br>  
              <label className="container">AWS-BEDROCK
                <input type="radio" value = "BedrockAI"   checked={selectedOption === "BedrockAI"}   onChange={onValueChange}/>
                <span class="checkmark"></span>
              </label><br></br>
              <label className="container">   OpenAI
                <input type="radio" value = "OpenAI"   checked={selectedOption === "OpenAI"}  onChange={onValueChange} />
                <span class="checkmark"></span>
              </label> 
<div> 
        </div>   
 </div>

      <div className="main">    
      <div className="top-center-text">   {
             selectedOption === 'OpenAI' ?             
            <div className="txtcolor1"> <img className='chatImg' src={OpenAI} alt="" /><p>GPT-3.5-TURBO-0301</p>  </div> :
            <div className="txtcolor2"> <img className='chatImg' src={BedrockAI} alt="" /><p>AI 21LABS Jurassic-2 Ultra</p> </div> 
      }  
      </div>
        <div className="chats">  
        {
            //  selectedOption === 'OpenAI' ?             
            //  <>                 
            //   {
              messages.map((message,i) => (  
               <div key={i} className={message.isBot?"chat bot":"chat"}>
                <img className='chatImg' src={message.isBot ? iconAI : userIcon} alt="" /><p className='txt'> {message.text}</p>  
               </div>
              ))  
             }          
           {    
                  isLoading &&
                  <div key={messages.length} className="flex justify-start">
                    <div className="dot-flashing">         
                    </div>
                  </div>
            } 
          {/* </> :   
          <>
            {
              messages.map((message,i) => (  
               <div key={i} className={message.isBot?"chat bot":"chat"}>
                <img className='chatImg' src={message.isBot ? BedrockAI : userIcon} alt="" /><p className='txt'> {message.text}</p>  
               </div>
              ))  
             }          
           {    
                  isLoading &&
                  <div key={messages.length} className="flex justify-start">
                    <div className="dot-flashing">         
                    </div>
                  </div>
            }   
          </>
 } */}
        <div ref={msgEnd}/>
        </div>   
        <div className="chatFooter">
           <div className="inp">
                <input type="text" placeholder='Send a message' value={input} onKeyDown={handleEnter} onChange={(e)=>{setInput(e.target.value)}}/> <button className="send" onClick={handleSend}><img src={sendBtn} alt="Send"/> </button>          
           </div>      
          <p>ChatGPT / AWS Bedrock may produce incorrect results</p>
        </div>
    </div>
    </div>
  );
}
export default App;